<template>
  <div class="my-page-inquiry my-page">
    <div class="wrapper">
      <div class="row">
        <div class="col-lg-4">
          <div class="form-group">
            <label class="subject font-sm" :for="`${component.name}InquiryCate`">문의 유형</label>
            <select :id="`${component.name}InquiryCate`" class="border-focus-point font-sm form-control" @change="setTitle($event)" v-model="state.form.inquiryCate" ref="inquiryCateRef">
              <option value="">문의 유형을 선택해주세요.</option>
              <option :value="t.value" v-for="(t, idx) in types" :key="idx">{{ t.title }}</option>
            </select>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="form-group">
            <label class="subject font-sm" :for="`${component.name}Title`">제목</label>
            <input :id="`${component.name}Title`" type="text" class="form-control border-focus-point font-sm" placeholder="ex) [프로젝트 참여/투자 관련] 문의가 있어요!" v-model="state.form.title" ref="titleRef"/>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="subject font-sm" :for="`${component.name}Content`">내용</label>
        <textarea :id="`${component.name}Content`" class="form-control border-focus-point font-sm" placeholder="문의 내용을 입력해주세요." v-model="state.form.content" ref="contentRef"></textarea>
      </div>
      <ul class="guide tight font-sm">
        <li>문의를 접수하시면 담당자가 답신 드리겠습니다.</li>
        <li>이용가이드를 이용하시면 보다 빠르게 해결하실 수 있습니다.</li>
      </ul>
      <div class="actions">
        <div class="row">
          <div class="link col-lg-6">
            <a class="btn btn-block btn-secondary font-sm" :href="$definitions.urls.generalGuide" target="_blank" rel="noopener noreferrer">이용가이드 바로가기</a>
          </div>
          <div class="col-lg-6">
            <button class="btn btn-point btn-block" @click="submit()" :disabled="!state.loaded">문의 접수</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive, ref} from "@vue/composition-api";
import http from "@/scripts/http";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";

function Component(initialize) {
  this.name = "pageMyPageInquiry";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    menu: Object
  },
  setup() {
    const component = new Component(() => {
      store.dispatch("setDocumentTitle", "문의하기");
    });

    const state = reactive({
      loaded: true,
      form: {
        inquiryCate: "",
        title: "",
        content: "",
      }
    });

    const types = [
      {value: "INQRYB_0001", title: "프로젝트 참여/투자 관련"},
      {value: "INQRYB_0002", title: "결제/환불 관련"},
      {value: "INQRYB_0003", title: "프로젝트 개설 관련"},
      {value: "INQRYB_0004", title: "기능오류 관련"},
      {value: "INQRYB_0005", title: "크라우드 펀딩 교육/제휴/언론 관련"},
      {value: "INQRYB_0006", title: "기타 문의"}
    ];

    const inquiryCateRef = ref();
    const titleRef = ref();
    const contentRef = ref();

    const setTitle = (e) => {
      const value = e.target.value;
      const title = types.find(t => t.value === value)?.title;

      state.form.title = "";

      if (title) {
        state.form.title = `[${title}] `;
        titleRef.value.focus();
      }
    };

    const submit = () => {
      if (!state.form.inquiryCate) {
        store.commit("setSwingMessage", "문의 유형을 선택해주세요.");
        inquiryCateRef.value.focus();
      } else if (!state.form.title) {
        store.commit("setSwingMessage", "제목을 입력해주세요.");
        titleRef.value.focus();
      } else if (!state.form.content) {
        store.commit("setSwingMessage", "내용을 입력해주세요.");
        contentRef.value.focus();
      } else {
        state.loaded = false;

        http.post("/api/inquiry", state.form).then(() => {
          state.loaded = true;
          store.commit("setSwingMessage", "문의하신 내용을 접수하였습니다. 담당자를 통해 빠르게 연락드리겠습니다.");

          state.form.inquiryCate = "";
          state.form.title = "";
          state.form.content = "";
        }).catch(httpError(() => {
          state.loaded = true;
        }));
      }
    };

    return {component, state, inquiryCateRef, titleRef, contentRef, setTitle, types, submit};
  }
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.mypage";

.my-page-inquiry {
  position: relative;

  > .wrapper {
    textarea {
      min-height: $px170;
      max-width: 100%;
      min-width: 100%;
      padding: $px15;
    }

    .actions {
      padding-top: $px20;

      .row {
        > div {
          padding-top: $px10;

          .btn {
            padding-top: $px16;
            padding-bottom: $px16;
            font-size: $px14;
          }
        }
      }
    }
  }
}
</style>